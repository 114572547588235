.status-panel-button {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: var(--button-primary-border-color);
  font-size: var(--body-text-size-large) !important;
  font-family: var(--main-font);
  width: 100%;
  height: auto;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.status-panel-button:hover {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border-color: var(--button-primary-border-color) !important;
}

.status-panel-button:disabled {
  background-color: var(--button-primary-background-disabled) !important;
  border-color: var(--button-primary-border-color-disabled) !important;
  color: var(--button-primary-color-disabled);
  cursor: not-allowed;
}

.status-panel-button .anticon {
  font-size: var(--body-text-size-large);
} 