.fade-out {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out; /* Adjust the duration and timing as needed */
  }
  
  .fade-out-list {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out; /* Adjust the duration and timing as needed */
  }
  
  .attributes-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  /* Style for the attribute add button */
  .add-attribute-button.ant-btn-primary {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  
  /* Style for the questions footer and add button */
  .questions-footer {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    border-top: 1px solid var(--background-accent-medium);
  }
  
  .add-question-button.ant-btn-primary {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 16px;
  }
  