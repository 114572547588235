/* IconBrowserCard.css */
.icon-browser-card {
    width: 100%;
    padding: 0px;
    border-color: var(--background-light) !important;
    border-width: 1px !important;
}
  
.icon-browser-card .ant-card {
    background-color: var(--background-medium) !important;
    border-radius: 8px !important;
    border: none !important;
}

.icon-browser-card .ant-card-bordered {
    background-color: var(--background-medium) !important;
    border-radius: 8px !important;
    border: none !important;
}

.icon-browser-card .ant-card-body {
    background-color: var(--background-medium) !important;
    border-radius: 8px !important;
}
  
.icon-browser-card .ant-card-head {
    background-color: var(--background-medium);
    border-color: var(--background-light);
}

.icon-browser-card .ant-card-head-title {
    color: var(--body-text-light);
    font-size: var(--body-text-size-large);
    margin-top: 8px !important;
}

.icon-browser-card .ant-card-body {
    background-color: var(--background-medium);
}
 
.file-list-card {
    height: 100% !important;
    overflow: hidden;
}

.file-list-card .ant-card-body {
    height: 100% !important;
    overflow: hidden; 
    background-color: var(--background-medium);
    border-color: var(--background-light) !important;
    border-width: 2px !important;

}

.file-list-scroll {
    height: 460px;
    overflow-y: auto;
    padding-right: 10px;
    padding-top: 0px !important;
    border-color: var(--background-light) !important;
    border-width: 2px !important;
}
  
.selected-icon-card {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.icon-grid-card {
    margin-top: 10px;
}
  
/* Page indicator text */
.icon-grid-card .ant-card-body span {
    color: var(--body-text-light);
}
  
/* Scrollbar styling */
.file-list-scroll::-webkit-scrollbar {
    width: 8px;
}
  
.file-list-scroll::-webkit-scrollbar-track {
    background: var(--background-dark);
    border-radius: 4px;
}
  
.file-list-scroll::-webkit-scrollbar-thumb {
    background: var(--background-light);
    border-radius: 4px;
}
  
.file-list-scroll::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}
  
/* Pagination buttons */
.pagination-button {
    background-color: var(--background-dark);
    border-color: var(--background-light);
    color: var(--body-text-light);
}
  
.pagination-button:hover:not(:disabled) {
    background-color: var(--background-light);
    border-color: var(--primary-color);
    color: var(--body-text-light);
}
  
.pagination-button:disabled {
    background-color: var(--background-dark);
    color: var(--body-text-medium);
}
  