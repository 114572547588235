/* Flexbox settings for centering the modal in both directions */
.attribute-modal .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed; /* Ensure the modal is positioned relative to the viewport */
    margin: 0; /* Remove any default margin */
    height: 100vh; /* Full viewport height */
}

/* Content settings */
.attribute-modal .ant-modal-content {
    border-radius: 8px;
    background-color: var(--background-color-medium);
    border: 1px solid var(--border-color-light);
    margin: auto; /* Ensure the content is centered */
}

/* Ensure the body inside the modal is correctly padded */
.attribute-modal .ant-modal-body {
    padding: 16px;
}

/* Remove any extra padding or margin from the modal container */
.attribute-modal {
    padding: 0 !important;
}

/* Ensure the header is styled consistently */
.attribute-modal .ant-modal-header {
    background-color: var(--background-color-medium);
    border-bottom: none;
}

/* Style the modal title */
.attribute-modal .ant-modal-title {
    color: var(--text-color-primary);
    font-size: 24px;
    font-weight: bold;
}

/* Hide the footer */
.attribute-modal .ant-modal-footer {
    display: none;
}

/* Additional custom styles for form items */
.custom-form-item {
    margin-bottom: 16px;
}

/* Style the close button */
.ant-modal-close-x {
    color: var(--title-text-light);
}

/* Ensure the size of the text is consistent */
.large-size {
    font-size: 16px;
}

/* Style primary buttons */
.primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

/* Style secondary buttons */
.secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

/* Hover effects for buttons */
.primary:hover, .secondary:hover {
    opacity: 0.9;
}

/* Focus effects for buttons */
.primary:focus, .secondary:focus {
    opacity: 0.9;
}

/* Flexbox layout for attributes header */
.attributes-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

/* Custom sizing for the Add Attribute button */
.add-attribute-button {
    margin-left: 16px;
    height: 24px !important;
    width: 24px !important;
}

.custom-modal .ant-modal-content {
  background-color: var(--background-medium) !important;
  border-radius: 4px;
}

.custom-modal .ant-modal-header {
  background-color: var(--background-medium) !important;
  border-bottom: 1px solid var(--background-dark) !important;
}

.custom-modal .ant-modal-title {
  color: var(--body-text-light) !important;
}

.custom-modal .ant-modal-close {
  color: var(--body-text-light) !important;
}

.custom-modal .ant-form-item-label > label {
  color: var(--body-text-light) !important;
  font-size: var(--body-text-size-large) !important;
}

.custom-modal .ant-input,
.custom-modal .ant-input-textarea textarea {
  font-size: var(--body-text-size-large) !important;
  line-height: 1.5 !important;
  background-color: var(--background-light) !important;
  border: 1px solid var(--background-medium) !important;
  color: var(--body-text-dark) !important;
  padding: 8px !important;
}

.custom-modal .ant-input::placeholder,
.custom-modal .ant-input-textarea textarea::placeholder {
  color: var(--body-text-medium);
  font-size: var(--body-text-size-large);
}

.custom-modal .ant-input:focus,
.custom-modal .ant-input:hover,
.custom-modal .ant-input-textarea textarea:focus,
.custom-modal .ant-input-textarea textarea:hover {
  background-color: var(--background-very-light-2) !important;
  border: 2px solid var(--background-dark) !important;
  color: var(--body-text-medium);
}
